import { SURVEY } from "../constant";
import {backend} from '../../config/config';

// function shuffle(a) {
//   for (let i = a.length - 1; i > 0; i--) {
//       const j = Math.floor(Math.random() * (i + 1));
//       [a[i], a[j]] = [a[j], a[i]];
//   }
//   return a;
// }

const StartSurvey = (callback) => {
  return {
    type: SURVEY.START,
    payload: fetch(backend+'/api/survey/start').then(res => {
      return res.json();
    }).then(res => {
      // console.log(res);
      if (res.ok) {
        sessionStorage.setItem("testTakerID", res.data.id);
        callback();
      }
      return res;
    })
  }
}

const GetQuestion = () => ({
  type: SURVEY.GET,
  payload: fetch(backend+'/api/survey/questions').then(res => {
    let data = res.json();
    // console.log(data);
    return data;
  }).then(data => {
    return data;
  })
})

const AnswerQuestion = (id, grade) => {
  return (dispatch, getState) => {
    const { testTakerInfo, page } = getState().survey;
    dispatch({
      type: SURVEY.ANSWER,
      payload: {
        questionID: id,
        grade: grade
      }
    })
    let data = {
      Page: page,
      Answer: {
        Grade: grade,
        QuestionID: id,
        TestTakerID: testTakerInfo.ID
      }
    }
    // console.log(data);
    dispatch({
      type: SURVEY.ANSWERUPLOAD,
      payload: fetch(backend+'/api/survey/answer', {
        method: 'POST',
        body: JSON.stringify(data)
      }).then(res => {
        return res.json();
      }).then(res => {
        if (!res.ok) {
          return Promise.reject();
        } else {
          return res;
        }
      })
    });
  };
}

const SubmitPersonal = (data, callback) => {
  return (dispatch, getState) => {
    data.ID = getState().survey.testTakerInfo.ID
    dispatch({
      type: SURVEY.SUBMIT,
      payload: fetch(backend+'/api/survey/end', {
        method: 'POST',
        body: JSON.stringify(data)
      }).then(res => {
        return res.json();
      }).then(res => {
        // console.log(res);
        callback();
        return res;
      })
    })
  }
}

const SubmitAppointment = (data) => {
  return (dispatch, getState) => {
    data.ID = getState().survey.testTakerInfo.ID
    dispatch({
      type: SURVEY.ADDITIONAL,
      payload: fetch(backend+'/api/survey/additional', {
        method: 'POST',
        body: JSON.stringify(data)
      }).then(res => {
        return res.json();
      }).then(res => {
        // console.log(res);
        return res;
      })
    })
  }
}


// const GetQuestionAction = () => {
//   return (dispatch) => {
//     const response = dispatch({
//       type: SURVEY.GET,
//       payload: fetch('/api/survey')
//     })
 
//     response.then((data) => {
//       dispatch(secondAction(data))
//     })
//   }
// }

export {
  StartSurvey,
  GetQuestion,
  AnswerQuestion,
  SubmitPersonal,
  SubmitAppointment
}