import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, TextareaAutosize, Button, Checkbox, InputLabel, Input, Select, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { SubmitPersonal } from '../../store/action/survey'


const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff !important",
    backgroundColor: "#626876",
    '&:hover': {
      backgroundColor: "#2d2c2c",
    },
  },
}))(Button);

const Personal = ({answers, submit, page}) => {
  const history = useHistory();
  const [gender, setGender] = useState(-1);
  const [age, setAge] = useState(-1);
  const [experience, setExperience] = useState(-1);
  const [comment, setComment] = useState("");
  const [email2, setEmail2] = useState("");
  const [checkbox2, setCheckbox2] = useState(false);

  const [tryNext, setTryNext] = useState(false);
  
  const sumUpData = () => {
    let data = {
      Age: parseInt(age),
      Gender: parseInt(gender),
      Remark: comment,
      ProfessionExp: parseInt(experience),
      NewsletterEmail: email2,
      Page: 19
    }
    return data;
  }
  const formValidation = () => {
    let result = (age !== -1) && (gender !== -1) && (experience !== -1);
    return result
  }

  const toSubmit = () => {
    setTryNext(true);
    if (formValidation()) {
      submit(sumUpData(), toNext)
    }
  }

  const toNext = () => {
    history.push("/survey/result");
  }

  return (
    <Container maxWidth="sm">
      {
        !formValidation() && tryNext ? (<div className="alert-box">Bitte beantworten Sie alle Fragen. </div>) : <div></div>
      }
      <FormControl component="fieldset" required fullWidth className="input-row">
        <FormLabel component="label">Geschlecht</FormLabel>
        <RadioGroup aria-label="gender" name="gender" value={gender} onChange={(event) => {setGender(event.target.value)}} row>
          <FormControlLabel value="2" control={<Radio />} label="weiblich" />
          <FormControlLabel value="1" control={<Radio />} label="männlich" />
          <FormControlLabel value="3" control={<Radio />} label="sonstige" />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" required fullWidth className="input-row">
        <FormLabel component="legend">Alter/Lebensweisheit</FormLabel>
        <RadioGroup aria-label="age" name="age" value={age} onChange={(event) => {setAge(event.target.value)}} row>
          <FormControlLabel value="1" control={<Radio />} label="unter 20" />
          <FormControlLabel value="2" control={<Radio />} label="20 - 30" />
          <FormControlLabel value="3" control={<Radio />} label="31 – 40" />
          <FormControlLabel value="4" control={<Radio />} label="41 - 60" />
          <FormControlLabel value="5" control={<Radio />} label="über 60" />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" required fullWidth className="input-row">
        <FormLabel component="legend">Persönlichkeitsentwicklung ist für mich</FormLabel>
        <Select
          value={experience}
          onChange={(event) => {setExperience(event.target.value)}}
          displayEmpty
        >
          <MenuItem value="-1">Bitte wählen Sie…</MenuItem>
          <MenuItem value="1">Neuland</MenuItem>
          <MenuItem value="2">sehe mich als AnfängerIn</MenuItem>
          <MenuItem value="3">bin erfahren</MenuItem>
          <MenuItem value="4">bin sehr erfahren</MenuItem>
        </Select>
      </FormControl>
      <FormControl component="fieldset" fullWidth className="input-row">
        <FormLabel component="legend">Sie haben Anmerkungen?</FormLabel>
        <TextareaAutosize className="comment" rows={4} value={comment} onChange={(event) => {setComment(event.target.value)}}/>
      </FormControl>
      <FormControlLabel
        className="checkbox input-row"
        control={<Checkbox checked={checkbox2} onChange={(event) => {setCheckbox2(event.target.checked)}} value="newsletter" />}
        label="Ich möchte den Souveränitäts-Newsletter zugesandt bekommen"
      />
      <FormControl disabled={!checkbox2} fullWidth className="input-row">
        <InputLabel>Ihre E-Mail-Adresse bitte</InputLabel>
        <Input value={email2} onChange={(event) => {setEmail2(event.target.value)}} />
      </FormControl>
      <ColorButton className="btn" onClick={() => {toSubmit()}}>Absenden !</ColorButton>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (data, callback) => dispatch(SubmitPersonal(data, callback))
  }
}

const mapStateToProps = (state) => {
  return {
    answers: state.survey.answers,
    page: state.survey.page
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Personal);