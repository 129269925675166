import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Dashboard from './dashboard';
import Motivations from './motivation';
import SearchPage from './search';

const Management = ({authenticate}) => {
  let { path } = useRouteMatch();
  // console.log(path)
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/dashboard`} />
      </Route>
      <PrivateRoute authenticate={authenticate} path={`${path}/dashboard`}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute authenticate={authenticate} path={`${path}/motivations`}>
        <Motivations />
      </PrivateRoute>
      <PrivateRoute authenticate={authenticate} path={`${path}/search`}>
        <SearchPage />
      </PrivateRoute>
    </Switch>
  )
}

function PrivateRoute({ children, authenticate, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticate ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state) => {
  return {
    authenticate: state.management.token !== '' && state.management.token ? true : false
  }
}

export default connect(mapStateToProps)(Management);