import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import StartPage from './start';
import Questionnaire from './questionnaire';
import Personal from './personal';
import Result from './result';
import "./index.scss";
import EmailConfirm from './emailConfirm';

const SurveyPage = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <StartPage />
      </Route>
      <Route path={`${path}/questionnaire`}>
        <Questionnaire />
      </Route>
      <Route path={`${path}/personal`}>
        <Personal />
      </Route>
      <Route path={`${path}/result`}>
        <Result />
      </Route>
      <Route path={`${path}/confirm/:type/:uuid`}>
        <EmailConfirm />
      </Route>
    </Switch>
  )
}

export default SurveyPage;