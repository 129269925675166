import {createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

// import MonitorReducer from './reducers/monitor';
// import websocketMiddleware from '../middleware/websocketMiddleware';
import SurveyReducer from './reducer/Survey';
import ManagementReducer from './reducer/Auth';

// let rootReducer = combineReducers({monitor: MonitorReducer});
let reducers = combineReducers({
  survey: SurveyReducer,
  management: ManagementReducer
});

// let store = createStore(MonitorReducer, {}, applyMiddleware(websocketMiddleware));
let store = createStore(reducers, applyMiddleware(ReduxThunk, promise))

export default store;