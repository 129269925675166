import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import MaterialTable from "material-table";
import { GetMotivations, CreateMotivation, UpdateMotivation, DeleteMotivation } from '../../store/action/management';
import { connect } from 'react-redux';

import "./index.scss";

import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



const Motivations = ({motivations, getMotivations, createMotivation, updateMotivation, deleteMotivation}) => {
  useEffect(() => {
    getMotivations()
  }, [getMotivations])
  return (
    <Container maxWidth="lg" className="main-content">
      <div className="motivations">
      <MaterialTable
        title="Motivationsprüche"
        icons={tableIcons}
        options={{
          pageSize: 20
        }}
        columns={[
          { title: "ID", field: "ID", hidden: true },
          { title: "Motivationsprüche", field: "Content" },
        ]}
        data={motivations}
        editable={{
          isEditable: rowData => rowData.name === "Content",
          onRowAdd: newData => {
            return new Promise((resolve, reject) => {
              createMotivation(newData.Content);
              resolve();
            })
          },
          onRowUpdate: (newData, oldData) => {
            return new Promise((resolve, reject) => {
              updateMotivation(newData.ID, newData.Content);
              resolve();
            })
          },
          onRowDelete: oldData => {
            return new Promise((resolve, reject) => {
              deleteMotivation(oldData.ID)
              resolve();
            })
          },
        }}
      />
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    motivations: state.management.motivations
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMotivations: () => dispatch(GetMotivations()),
    createMotivation: (motivation) => dispatch(CreateMotivation(motivation)),
    updateMotivation: (id, motivation) => dispatch(UpdateMotivation(id, motivation)),
    deleteMotivation: (id) => dispatch(DeleteMotivation(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Motivations);