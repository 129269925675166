import React, { useState } from 'react';
import { Container, Typography, Button, FormControl, InputLabel, Input } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Login } from '../../store/action/management';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {useCookies } from 'react-cookie';

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff !important",
    backgroundColor: "#626876",
    '&:hover': {
      backgroundColor: "#2d2c2c",
    },
  },
}))(Button);

const Auth = ({login}) => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(['management']);
  const toNext = (token) => {
    setCookie("token", token);
    history.push("/");
  }
  return (
    <Container maxWidth="xs">
      <Typography>Management Login</Typography>
      <FormControl fullWidth>
        <InputLabel>Username</InputLabel>
        <Input value={username} onChange={(event) => {setUsername(event.target.value)}} />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Password</InputLabel>
        <Input value={password} type="password" onChange={(event) => {setPassword(event.target.value)}} />
      </FormControl>
      <ColorButton onClick={() => {login(username, password, toNext)}}>Login</ColorButton>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password, callback) => dispatch(Login(username, password, callback))
  }
}

export default connect(null, mapDispatchToProps)(Auth);