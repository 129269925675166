import React, { useState } from 'react';
import { Container, FormControl, InputLabel, Input, Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { SearchTestTaker } from '../../store/action/management';
import { ages_mapping, experiences_mapping, gender_mapping } from '../../store/constant/index';
import { Polar } from 'react-chartjs-2';


const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff !important",
    backgroundColor: "#626876",
    '&:hover': {
      backgroundColor: "#2d2c2c",
    },
  },
}))(Button);

const SearchPage = ({testTaker, answers, sum, search, result}) => {
  const [id, setId] = useState("")
  const data = () => {
    let dataset = [];
    let labels = [];
    sum.forEach(elem => {
      dataset.push(elem.Sum);
      labels.push(elem.Title);
    });
    return {
      datasets: [{
        data: dataset,
        backgroundColor: [
          '#FF6384',
          '#4BC0C0',
          '#FFCE56',
          '#E7E9ED',
          '#36A2EB',
          '#ffa500',
          '#673ab7'
        ],
        label: 'Ergebnis' // for legend
      }],
      labels: labels
    };
  };
  return (
    <Container maxWidth="lg" className="search">
      <div className="search-bar">
        <FormControl>
          <InputLabel>Umfrage-ID</InputLabel>
          <Input className="btn" value={id} onChange={(event) => {setId(event.target.value)}} />
        </FormControl>
        <ColorButton onClick={() => {search(id)}}>Suchen</ColorButton>
      </div>
      <Typography variant="h4">Info</Typography>
      <div className="search-result-metadata">
        <div className="info-row">
          <div>Alter/Lebensweisheit: </div><div>{testTaker ? ages_mapping.get(testTaker.Age) : ''}</div>
        </div>
        <div className="info-row">
          <div>Geschlecht: </div><div>{testTaker ? gender_mapping.get(testTaker.Gender) : ''}</div>
        </div>
        <div className="info-row">
          <div>Persönlichkeitsentwicklung ist für mich: </div><div>{testTaker ? experiences_mapping.get(testTaker.ProfessionExp) : ''}</div>
        </div>
        <div className="info-row">
          <div>Email: </div><div>{testTaker ? testTaker.Email : ''}</div>
        </div>
        <div className="info-row">
          <div>Tel: </div><div>{testTaker ? testTaker.Tel : ''}</div>
        </div>
        <div className="info-row">
          <div>Name: </div><div>{testTaker ? testTaker.Name : ''}</div>
        </div>
        <div className="info-row">
          <div>Vorname: </div><div>{testTaker ? testTaker.Givenname : ''}</div>
        </div>
        <div className="info-row">Remark:</div>
        <div className="remark-row">{testTaker ? testTaker.Remark : ''}</div>
      </div>

      <Typography variant="h4">Summary</Typography>
      <Polar data={data()} options={{
        responsive: true,
        scale: {
          ticks: {
            stepSize: 14,
            min: 0,
            max: 84
          }
        },
      }}/>
      <br/>
      <div className="result-list">
        {
          result.map((elem, index) => {
            return (<div key={index} className="result-item">{elem}</div>);
          })
        }
      </div>
      <br/>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    testTaker: state.management.detail.testTaker ? state.management.detail.testTaker : {},
    answers: state.management.detail.answers ? state.management.detail.answers : [],
    sum: state.management.detail.sumAnswers ? state.management.detail.sumAnswers : [],
    result: state.management.detail.result ? state.management.detail.result : []
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    search: (id) => dispatch(SearchTestTaker(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);