import React, { useEffect } from 'react';
import { Container, Typography, Divider } from '@material-ui/core';
import { Bar, Pie, HorizontalBar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { GetPages, GetQuestions, GetExperiences, GetGenders, GetAges } from '../../store/action/management';
import { ages_mapping, experiences_mapping, gender_mapping } from '../../store/constant/index'

const Dashboard = ({pages, questions, ages, experiences, genders, getPages, getQuestions, getAges, getExperiences, getGenders}) => {
  const page_data = () => {
    let dataset = [];
    let labels = [];
    for (let i = 1; i <= 20; i++) {
      dataset.push(0);
      labels.push('Seite '+i);
    }
    if (pages) {
      questions.forEach(elem => {
        dataset[elem.Question-1] = elem.Number;
        // dataset.push(elem.Number);
        // labels.push(elem.Question);
      });
    }

    // console.log(dataset, labels);
    return {
      datasets: [{
        data: dataset,
        backgroundColor: '#FF6384',
        label: 'Teilnehmer',
      }],
      labels: labels
    };
  };
  const question_data = () => {
    let dataset = [];
    let labels = [];
    for (let i = 1; i <= 98; i++) {
      dataset.push(0);
      labels.push('Frage '+i);
    }
    if (questions) {
      questions.forEach(elem => {
        dataset[elem.Question-1] = elem.Number;
        // dataset.push(elem.Number);
        // labels.push(elem.Question);
      });
    }

    // console.log(dataset, labels);
    return {
      datasets: [{
        data: dataset,
        backgroundColor: '#4BC0C0',
        label: 'Teilnehmer' // for legend
      }],
      labels: labels,
    };
  };

  const age_data = () => {
    let dataset = [];
    let labels = [];
    for (let i = 1; i <= 5; i++) {
      dataset.push(0);
      labels.push(ages_mapping.get(i));
    }
    if (ages) {
      ages.forEach(elem => {
        dataset[elem.Age - 1] = elem.Number;
        // if (elem.Age === 0) return;
        // dataset.push(elem.Number);
        // labels.push(ages_mapping.get(elem.Age));
      });
    }
    // console.log(dataset, labels);
    return {
      datasets: [{
        data: dataset,
        backgroundColor: '#FFCE56',
        label: 'Teilnehmer' // for legend
      }],
      labels: labels
    };
  };

  const experience_data = () => {
    let dataset = [];
    let labels = [];
    for (let i = 1; i <= 4; i++) {
      dataset.push(0);
      labels.push(experiences_mapping.get(i));
    }
    if (experiences) {
      experiences.forEach(elem => {
        dataset[elem.Experience - 1] = elem.Number;
        // if (elem.Experience === 0) return;
        // dataset.push(elem.Number);
        // labels.push(experiences_mapping.get(elem.Experience));
      });
    }
    // console.log(dataset, labels);
    return {
      datasets: [{
        data: dataset,
        backgroundColor: '#E7E9ED',
        label: 'Teilnehmer' // for legend
      }],
      labels: labels
    };
  };

  const gender_data = () => {
    let dataset = [];
    let labels = [];
    if (genders) {
      genders.forEach(elem => {
        if (elem.Gender === 0) return;
        dataset.push(elem.Number);
        labels.push(gender_mapping.get(elem.Gender));
      });
    }

    // console.log(dataset, labels);
    return {
      datasets: [{
        data: dataset,
        backgroundColor: [
          '#36A2EB',
          '#ffa500',
          '#673ab7'
        ],
        label: 'Teilnehmer' // for legend
      }],
      labels: labels
    };
  };

  useEffect(() => {
    getPages();
    getQuestions();
    getAges();
    getExperiences();
    getGenders();
  }, [getPages, getQuestions, getAges, getExperiences, getGenders])

  return (
    <Container maxWidth="lg" className="main-content">
      <div className="row">
        <Typography>Export CSV</Typography>
        <div>
          <a href={window.location.hostname+"/api/management/dashboard/download"}>Download</a>
        </div>
      </div>
      <Divider />
      <div className="row">
        <Typography>Export Newsletter CSV</Typography>
        <div>
          <a href={window.location.hostname+"/api/management/dashboard/download_newsletter"}>Download</a>
        </div>
      </div>
      <Divider />
      <div className="row">
        <Typography>Anzahl Teilnehmer pro Seite</Typography>
        <Bar data={page_data()} options={{
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 1
              }
            }]
          }
        }}/>
      </div>
      <div className="row">
        <Typography>Teilnehmer pro Frage</Typography>
        <HorizontalBar
          height={500}
          data={question_data()} options={{
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  stepSize: 1
                }
              }],
              xAxes: [{
                ticks: {
                  beginAtZero: true,
                  stepSize: 1
                }
              }]
            }
          }}
        />
      </div>
      <div className="row">
        <Typography>Altersstruktur</Typography>
        <Bar data={age_data()} options={{
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 1
              }
            }]
          }
        }}/>
      </div>
      <div>
        <Typography>Erfahrungsstatus</Typography>
        <Bar data={experience_data()} options={{
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 1
              }
            }]
          }
        }}/>
      </div>
      <div>
        <Typography>Geschlecht</Typography>
        <Pie data={gender_data()} />
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    pages: state.management.pages,
    questions: state.management.questions,
    ages: state.management.ages,
    experiences: state.management.experiences,
    genders: state.management.genders
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPages: () => dispatch(GetPages()),
    getQuestions: () => dispatch(GetQuestions()),
    getAges: () => dispatch(GetAges()),
    getExperiences: () => dispatch(GetExperiences()),
    getGenders: () => dispatch(GetGenders())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);