import React, { useState, useEffect } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress';
import { lighten, withStyles } from '@material-ui/core/styles';
import { Container, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import "./questionnaire.scss";

import Question from '../../components/Question';
import { GetQuestion } from '../../store/action/survey';
import { Link, withRouter, useHistory } from 'react-router-dom';

const BorderLinearProgress = withStyles({
  root: {
    height: 20,
    borderRadius: 20,
    backgroundColor: lighten('#626876', 0.5),
  },
  // bar: {
  //   borderRadius: 20,
  //   backgroundColor: '#B5123E',
  // },
  // bar2Buffer: {
  //   borderRadius: 20,
  //   backgroundColor: lighten('#626876', 0.5),
  //   opacity: 0.4
  // }
})(LinearProgress);

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff !important",
    backgroundColor: "#626876",
    '&:hover': {
      backgroundColor: "#2d2c2c",
    },
  },
}))(Button);

const Questionnaire = ({questions, finish, page, length, get, back, next, submit, motivations, answers}) => {
  const [tryNext, setTryNext] = useState(false);

  const history = useHistory();
  useEffect(() => {
    let testTakerID = sessionStorage.getItem("testTakerID");
    if (!testTakerID || testTakerID === "") {
      history.replace("/survey");
    }
    get();
  }, [history, get])

  const warning = () => {
    let result = true;
    questions.forEach(elem => {
      result = result && answers.has(elem.ID);
    });
    return result;
  }

  const nextPage = () => {
    if (!warning()) {
      setTryNext(true);
      return;
    } else {
      setTryNext(false);
      next()
    }
  }

  return (
    <Container maxWidth="md" className="survey-page">
      <BorderLinearProgress
        variant="buffer"
        value={parseInt(((page)*5/length)*100)}
        className="progress-bar"
        valueBuffer={parseInt(((page+1)*5/length)*100)}
      />
      {
        !warning() && tryNext ? (<div className="alert-box">Bitte beantworten Sie alle Fragen. </div>) : <div></div>
      }
      <div className="question-list">
        <div className="question-header">
          <div>Frage</div>
          <div className="answer-label">
            <div className="left">
              überhaupt nicht
            </div>
            <div>
              voll und ganz
            </div>
          </div>
        </div>
        {
          questions.map((elem, index) => {
            return (<Question key={elem.ID} id={elem.ID} title={elem.Content} />);
          })
        }
      </div>
      <div className="pager">
        <div className="pager-wrapper">
          <ColorButton onClick={back}>zurück</ColorButton>
          {finish ? (<ColorButton onClick={submit}><Link to="/survey/personal" className="link-text">weiter</Link></ColorButton>):(<ColorButton onClick={nextPage}>weiter</ColorButton>)}
        </div>
      </div>
      <div className="motivation">
        {motivations.length !== 0 ?  (page+1) + '. ' + motivations[page%motivations.length].Content : ''}
      </div>
    </Container>
  )
}
 
const mapStateToProps = (state) => {
  return {
    questions: state.survey.questions.length !== 0 ? state.survey.questions.slice(state.survey.page*5, (state.survey.page+1)*5) : [],
    // questions: state.questions,
    finish: state.survey.questions.length <= 5*(state.survey.page+1),
    page: state.survey.page,
    length: state.survey.questions.length,
    testTaker: state.survey.testTakerInfo,
    motivations: state.survey.motivations,
    answers: state.survey.answers
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    back: () => dispatch({type: 'SURVEY.BACK'}),
    next: () => dispatch({type: 'SURVEY.NEXT'}),
    submit: () => dispatch({type: 'SURVEY.SUBMIT'}),
    get: () => dispatch(GetQuestion())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Questionnaire));