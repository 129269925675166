import React, { useRef, useState } from 'react';
import { Container, Button, FormControlLabel, Checkbox, Input, InputLabel, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { Polar } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import { SubmitAppointment } from '../../store/action/survey';

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff !important",
    backgroundColor: "#626876",
    '&:hover': {
      backgroundColor: "#2d2c2c",
    },
  },
}))(Button);

class Result extends React.Component {
  constructor(props) {
    super(props);
    let now = new Date();
    this.currentDate = now.toLocaleDateString("de-DE");
  }

  render() {
    return (
      <Container maxWidth="md" className="result-wrapper">
        <h2 className="title">Ihre aktuelle Souveränitäts-Formel</h2>
        <div>Ihre Umfrage-ID: {this.props.testTaker}</div>
        <br />
        <div>
          Diese Auswertung spiegelt Ihnen die aktuelle Gewichtung Ihrer Fähigkeiten und Ausprägungen und bezieht sich auf die Situation, die Sie ggf. beim Beantworten vor Augen hatten. Lassen Sie sich von Ihrem Ergebnis anregen und prüfen Sie.
        </div>
        <br/>
        <div>Entspricht das Ergebnis Ihrer persönlichen Einschätzung?</div>
        <ul>
          <li>Entspricht das Ergebnis Ihrer persönlichen Einschätzung? </li>
          <li>Hätten Sie gerne ein anders Ergebnis gehabt?</li>
          <li>Was entspricht nicht ihrem eigenen Selbstbild?</li>
          <li>Was überrascht Sie?</li>
          <li>Über was freuen Sie sich?</li>
        </ul>
        <div>
          Mehr erleben Sie in einem individuellen Coaching oder in einem Seminar. Denn diese persönlichen Qualitäten leben vom Erleben. Sie können Ihre Qualitäten und Fähigkeiten im wahrsten Sinne des Wortes sehen, spüren und nachvollziehen. Sie gewinnen Erkenntnisse, die Sie nachhaltig prägen und konkret nutzen werden. Nehmen Sie Kontakt auf.
        </div>
        <br/>
        <div>
          In einem kostenfreien 1⁄2 std. telefonischen Gespräch biete ich Ihnen eine Vertiefung zu Ihrem Ergebnis an. Haben Sie mir Ihre Emaildaten dafür zur Verfügung gestellt, werde ich in den nächsten Tagen Kontakt mit Ihnen aufnehmen
        </div>
        <br/>
        <div className="result-graph">
          <Polar data={this.props.answers} options={{
            responsive: true,
            scale: {
              ticks: {
                stepSize: 14,
                min: 0,
                max: 84
              }
            },
          }}/>
        </div>
        <div className="result-list">
          {
            this.props.result.map((elem, index) => {
              return (<div key={index} className="result-item">{elem}</div>);
            })
          }
        </div>
        <br/>
        <p>Gute Anregungen wünscht</p><br/>
        <p><i><strong>Walburga Ludwig</strong></i></p>
        <p>Ihre Souveränitäts-Entwicklerin</p>
        <div className="copyright">
          Selbstcheck zumr Souveränitäts-FormelCode © Walburga Ludwig, souveraenitaet@walburga-ludwig.de, {this.currentDate}
        </div>
      </Container>
    )
  }
}

const ResultWithPrint = ({result, answers, id, confirmed, submit}) => {
  const componentRef = useRef();
  const data = () => {
    let dataset = [];
    let labels = [];
    answers.forEach(elem => {
      dataset.push(elem.Sum);
      labels.push(elem.Title);
    });
    return {
      datasets: [{
        data: dataset,
        backgroundColor: [
          '#FF6384',
          '#4BC0C0',
          '#FFCE56',
          '#E7E9ED',
          '#36A2EB',
          '#ffa500',
          '#673ab7'
        ],
        label: 'Ergebnis' // for legend
      }],
      labels: labels
    };
  };
  const [checkbox1, setCheckbox1] = useState(false);
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [givenname, setGivenname] = useState("");

  const [tryNext, setTryNext] = useState(false);

  const formValidation = () => {
    let result = (email !== "") && (tel!== "") && (name !== "") && (givenname !== "");
    return result
  }

  const toSubmit = () => {
    setTryNext(true);
    if (formValidation()) {
      let data = {
        Email: email,
        Tel: tel,
        Name: name,
        Givenname: givenname
      }
      submit(data);
    }
  }

  return (
    <Container maxWidth="md">
      <Result result={result} answers={data()} testTaker={id} ref={componentRef} />
      {!confirmed ? (
        <Container maxWidth="md">
          {
            !formValidation() && tryNext ? (<div className="alert-box">Bitte beantworten Sie alle Fragen. </div>) : <div></div>
          }
          <FormControlLabel
            className="checkbox input-row"
            control={<Checkbox checked={checkbox1} onChange={(event) => {setCheckbox1(event.target.checked)}} value="email" />}
            label="Ich möchte ein 1/2 std. kostenfreies Gespräch (telefonisch) mein Ergebnis besprechen"
          />
          <FormControl disabled={!checkbox1} fullWidth className="input-row">
            <InputLabel>E-Mail-Adresse bitte</InputLabel>
            <Input value={email} onChange={(event) => {setEmail(event.target.value)}} />
          </FormControl>
          <FormControl disabled={!checkbox1} fullWidth className="input-row">
            <InputLabel>Name</InputLabel>
            <Input value={name} onChange={(event) => {setName(event.target.value)}} />
          </FormControl>
          <FormControl disabled={!checkbox1} fullWidth className="input-row">
            <InputLabel>Vorname</InputLabel>
            <Input value={givenname} onChange={(event) => {setGivenname(event.target.value)}} />
          </FormControl>
          <FormControl disabled={!checkbox1} fullWidth className="input-row">
            <InputLabel>Telefonnummer</InputLabel>
            <Input value={tel} onChange={(event) => {setTel(event.target.value)}} />
          </FormControl>
          <ColorButton onClick={() => {toSubmit()}} className="btn">Absenden !</ColorButton>
        </Container>
      ) : (<div></div>)}
        {confirmed ? (
          <div className="print-btn">
          <ReactToPrint
            trigger={() => <ColorButton>Drucken</ColorButton>}
            content={() => componentRef.current}
          />
        </div>
        ) : (<div></div>) }
      <ColorButton className="btn"><a className="link" href="https://walburgaludwig.de">Zurück zur Website</a></ColorButton>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.survey.result,
    answers: state.survey.sumUpAnswers,
    id: state.survey.testTakerInfo.surveyID,
    confirmed: state.survey.confirmed
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (data) => dispatch(SubmitAppointment(data))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultWithPrint));