
const SURVEY = {
  START: 'SURVEY.START',
  GET: 'SURVEY.GET',
  ANSWER: 'SURVET.ANSWER',
  ANSWERUPLOAD: 'SURVEY.ANSWERUPLOAD',
  NEXT: 'SURVEY.NEXT',
  BACK: 'SURVEY.BACK',
  SUBMIT: 'SURVEY.SUBMIT',
  ADDITIONAL: 'SURVEY.ADDITIONAL'
}

const EMAIL = {
  NEWSLETTER: 'EMAIL.NEWSLETTER',
  APPOINTMENT: 'EMAIL.APPOINTMENT',
  CONFIRM: 'EMAIL.CONFIRM'
}

const MANAGEMENT = {
  LOGOUT: 'MANAGEMENT.LOGOUT',
  SETCOOKIE: 'MANAGEMENT.SETCOOKIE',
  LOGIN: 'MANAGEMENT.LOGIN',
  GET: 'MANAGEMENT.GET',
  GETPAGES: 'MANAGEMENT.GETPAGES',
  GETQUESTIONS: 'MANAGEMENT.GETQUESTIONS',
  GETAGES: 'MANAGEMENT.GETAGES',
  GETEXPERIENCES: 'MANAGEMENT.GETEXPERIENCES',
  GETGENDERS: 'MANAGEMENT.GETGENDERS',
  GETNEWSLETTER: 'MANAGEMENT.NEWSLETTER',
  SEARCH: 'MANAGEMENT.SEARCH'
}

const MOTIVATION = {
  GET: 'MOTIVATION.GET',
  CREATE: 'MOTIVATION.CREATE',
  UPDATE: 'MOTIVATION.UPDATE',
  DELETE: 'MOTIVATION.DELETE',
}

const WS = {
  CONNECT: 'WS.CONNECT',
  CONNECTING: 'WS.CONNECTING',
  CONNECTED: 'WS.CONNECTED',
  DISCONNECT: 'WS.DISCONNECT',
  DISCONNECTED: 'WS.DISCONNECTED',
  SEND: 'WS.SEND'
}
const ages_mapping = new Map([[1, "unter 20"], [2, "20 - 30"], [3, "31 – 40"], [4, "41 - 60"], [5, "über 60"]]);
const experiences_mapping = new Map([[1, "Neuland"], [2, "sehe mich als AnfängerIn"], [3, "bin erfahren"], [4, "bin sehr erfahren"]]);
const gender_mapping = new Map([[1, "männlich"], [2, "weiblich"], [3, "sonstige"]]);

export {
  WS,
  SURVEY,
  MANAGEMENT,
  MOTIVATION,
  EMAIL,
  ages_mapping,
  experiences_mapping,
  gender_mapping
}