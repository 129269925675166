import SHA256 from 'crypto-js/sha256';
import Cookies from 'js-cookie';
import { MANAGEMENT, MOTIVATION, EMAIL } from "../constant"
import {backend} from '../../config/config';

const Login = (username, password, callback) => {
  // const [cookies, setCookie, removeCookie] = useCookies(['management']);
  // const history = useHistory();
  let data = {
    username: username,
    password: encodeURIComponent(SHA256(password, 'BORSCH.NET'))
  }
  return {
    type: MANAGEMENT.LOGIN,
    payload: fetch(backend+"/api/management/auth", {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(res => {
      return res.json();
    }).then(data => {
      if (data.ok) {
        // setCookie("token", data.data.token);
        callback(data.data.token);
        // history.push("/management/dashboard");
      }
      return data;
    })
  }
}

const Logout = () => {
  Cookies.remove("token");
  return {
    type: MANAGEMENT.LOGOUT
  }
}

const GetPages = () => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGEMENT.GETPAGES,
      payload: fetch(backend+"/api/management/dashboard/pages", {
        method: 'GET',
        headers: {
          'Authorization': getState().management.token
        }
      }).then(res => {
        return res.json();
      })
    })
  }
}

const GetQuestions = () => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGEMENT.GETQUESTIONS,
      payload: fetch(backend+"/api/management/dashboard/questions", {
        method: 'GET',
        headers: {
          'Authorization': getState().management.token
        }
      }).then(res => {
        return res.json();
      })
    })
  }
}

const GetAges = () => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGEMENT.GETAGES,
      payload: fetch(backend+"/api/management/dashboard/ages", {
        method: 'GET',
        headers: {
          'Authorization': getState().management.token
        }
      }).then(res => {
        return res.json();
      })
    })
  }
}

const GetExperiences = () => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGEMENT.GETEXPERIENCES,
      payload: fetch(backend+"/api/management/dashboard/experiences", {
        method: 'GET',
        headers: {
          'Authorization': getState().management.token
        }
      }).then(res => {
        return res.json();
      })
    })
  }
}

const GetGenders = () => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGEMENT.GETGENDERS,
      payload: fetch(backend+"/api/management/dashboard/genders", {
        method: 'GET',
        headers: {
          'Authorization': getState().management.token
        }
      }).then(res => {
        return res.json();
      })
    })
  }
}

const SetCookie = (token) => {
  return {
    type: MANAGEMENT.SETCOOKIE,
    payload: token
  }
}

const CreateMotivation = (motivation) => {
  let data = {
    Content: motivation
  }
  return (dispatch, getState) => {
    dispatch({
      type: MOTIVATION.CREATE,
      payload: fetch(backend+"/api/management/motivation", {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Authorization': getState().management.token
        }
      }).then(res => {
        return res.json();
      }).then(res => {
        if (res.ok) {
          dispatch(GetMotivations())
        }
      })
    })
  }
}

const GetMotivations = () => {
  return (dispatch, getState) => {
    dispatch({
      type: MOTIVATION.GET,
      payload: fetch(backend+"/api/management/motivation", {
        method: 'GET',
        headers: {
          'Authorization': getState().management.token
        }
      }).then(res => {
        return res.json();
      })
    })
  }
}

const UpdateMotivation = (id, motivation) => {
  let data = {
    ID: id,
    Content: motivation
  }
  return (dispatch, getState) => {
    dispatch({
      type: MOTIVATION.UPDATE,
      payload: fetch(backend+"/api/management/motivation", {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Authorization': getState().management.token
        }
      }).then(res => {
        return res.json();
      }).then(res => {
        if (res.ok) {
          dispatch(GetMotivations())
        }
      })
    })
  }
}

const DeleteMotivation = (id, motivation) => {
  let data = {
    ID: id,
    Content: motivation
  }
  return (dispatch, getState) => {
    dispatch({
      type: MOTIVATION.DELETE,
      payload: fetch(backend+"/api/management/motivation", {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
          'Authorization': getState().management.token
        }
      }).then(res => {
        return res.json();
      }).then(res => {
        if (res.ok) {
          dispatch(GetMotivations())
        }
      })
    })
  }
}

const ConfirmNewsletter = (uuid) => {
  let data = {
    UUID: uuid
  }
  console.log(data);
  return (dispatch, getState) => {
    dispatch({
      type: EMAIL.NEWSLETTER,
      payload: fetch(backend+"/api/survey/newsletter", {
        method: 'POST',
        body: JSON.stringify(data),
      }).then(res => {
        return res.json();
      }).then(res => {
        // return res;
        console.log(res);
          // dispatch(GetMotivations())
        dispatch({
          type: EMAIL.CONFIRM,
          payload: res
        })
      })
    })
  }
}

const ConfirmAppointment = (uuid) => {
  let data = {
    UUID: uuid
  }
  console.log(data);
  return (dispatch, getState) => {
    dispatch({
      type: EMAIL.NEWSLETTER,
      payload: fetch(backend+"/api/survey/appointment", {
        method: 'POST',
        body: JSON.stringify(data),
      }).then(res => {
        return res.json();
      }).then(res => {
        if (res.ok) {
          // dispatch(GetMotivations())
          dispatch({
            type: EMAIL.CONFIRM
          })
        }
      })
    })
  }
}

const SearchTestTaker = (id) => {
  let data = {
    survey_id: id
  }
  return (dispatch, getState) => {
    dispatch({
      type: MANAGEMENT.SEARCH,
      payload: fetch(backend+"/api/management/search", {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Authorization': getState().management.token
        }
      }).then(res => {
        return res.json();
      })
    })
  }
}

export {
  Login,
  GetPages,
  GetQuestions,
  GetExperiences,
  GetAges,
  GetGenders,
  SetCookie,
  Logout,
  GetMotivations,
  CreateMotivation,
  UpdateMotivation,
  DeleteMotivation,
  ConfirmAppointment,
  ConfirmNewsletter,
  SearchTestTaker
}