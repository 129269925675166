import { SURVEY, EMAIL } from '../constant';

const initialState = {
  questions: [],
  motivations: [],
  page: 0,
  answers: new Map(),
  warning: true,
  testTakerInfo: {
    ID: parseInt(sessionStorage.getItem("testTakerID")),
    surveyID: 'Bitte füllen Sie die Kontaktfelder aus, um zu erhalten'
  },
  result: [],
  sumUpAnswers: [],
  confirmEmail: false,
  confirmMessage: ''
}

const SurveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY.ADDITIONAL+'_FULFILLED':
      state.confirmed = true;
      if (action.payload.data) {
        state.testTakerInfo.surveyID = action.payload.data.survey_id;
      }
      return {
        ...state
      }
    case EMAIL.CONFIRM:
      // console.log(action)
      state.confirmEmail = true;
      state.confirmMessage = action.payload.data;
      return {
        ...state
      }
    case SURVEY.START+'_FULFILLED':
      state.testTakerInfo.ID = action.payload.data.id;
      return {
        ...state
      }
    case SURVEY.GET + '_FULFILLED':
      return {
        ...state,
        questions: action.payload.data.questions,
        motivations: action.payload.data.motivations
      };
    case SURVEY.ANSWER:
      state.answers.set(action.payload.questionID, action.payload.grade);
      if (state.answers.size !== state.questions.length) {
        state.warning = true
      } else {
        state.warning = false
      }
      return {
        ...state
      }
    case SURVEY.BACK:
      if (state.page > 0) {
        state.page = state.page - 1;
      }
      return {
        ...state
      }
    case SURVEY.NEXT:
      state.page = state.page + 1;
      return {
        ...state
      }
    case SURVEY.SUBMIT:
      return {
        ...state
      }
    case SURVEY.SUBMIT + '_FULFILLED':
      if (action.payload.data.result) {
        state.result = action.payload.data.result;
      }
      if (action.payload.data.answers) {
        state.sumUpAnswers = action.payload.data.answers;
      }
      state.answers = new Map();
      return {
        ...state
      }
    default:
      return state;
  }
}

export default SurveyReducer;
