import React from 'react';
import clsx from 'clsx';
import { Radio, makeStyles } from '@material-ui/core';
import "./Question.scss";
import { AnswerQuestion } from '../store/action/survey';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#B5123E',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#B5123E',
    },
  },
});

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const Question = ({id, title, answer, currentPage, value}) => {
  // console.log(storeValue);
  // const [value, setValue] = useState(storeValue);
  const handleChange = (event) => {
    // console.log(event.target.value);
    // setValue(event.target.value);
    answer(id, parseInt(event.target.value))
    // console.log(value);
  };
  return (
    <div className="question">
      <div className="question-wrapper">
        <div className="question-content">{title}</div>
        <div className="answer-label">
          <div className="left">
            überhaupt nicht
          </div>
          <div>
            voll und ganz
          </div>
        </div>
        <div className="question-answer">
          <StyledRadio
            checked={value === 0}
            onChange={handleChange}
            value={0}
          />
          <StyledRadio
            checked={value === 1}
            onChange={handleChange}
            value={1}
          />
          <StyledRadio
            checked={value === 2}
            onChange={handleChange}
            value={2}
          />
          <StyledRadio
            checked={value === 3}
            onChange={handleChange}
            value={3}
          />
          <StyledRadio
            checked={value === 4}
            onChange={handleChange}
            value={4}
          />
          <StyledRadio
            checked={value === 5}
            onChange={handleChange}
            value={5}
          />
          <StyledRadio
            checked={value === 6}
            onChange={handleChange}
            value={6}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentPage: state.survey.page,
    value: state.survey.answers.get(ownProps.id)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    answer: (id, grade) => dispatch(AnswerQuestion(id, grade))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Question);