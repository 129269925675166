import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import SurveyPage from '../pages/survey';
import Management from '../pages/management';
import Auth from '../pages/management/auth';


const AppRouter = () => {
  return (
    <Switch>
      <Route path="/management">
        <Management />
      </Route>
      <Route path="/survey">
        <SurveyPage />
      </Route>
      <Route path="/login">
        <Auth />
      </Route>
      <Route path="/">
        <Redirect to="/survey" />
      </Route>
    </Switch>
  )
}

export default AppRouter;