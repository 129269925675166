import React, { useState } from 'react'
import { AppBar, Toolbar, Typography, Button, Container, IconButton, Divider, Drawer, List, ListItemIcon, ListItem, ListItemText } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import "./Header.scss"
import { Link, useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Logout } from '../store/action/management';
import logo from './logo.png';

const Header = ({login, logout}) => {
  let location = useLocation()

  const [open, setOpen] = useState(false);
  const history = useHistory();
  const toLogout = () => {
    logout();
    history.replace("/");
  }

  if (login) {
    return (
      <>
        <AppBar position="static" className="management" color="default">
          <Container maxWidth="lg">
            <Toolbar className="management-header-wrapper">
              <div className="logo-bar">
                <IconButton onClick={() => setOpen(true)}>
                  <MenuIcon/>
                </IconButton>
                <Typography>
                  Management Backend
                </Typography>
              </div>
              <div>
                <Button color="inherit" onClick={() => toLogout()}>Logout</Button>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
        <Drawer
          variant="persistent"
          anchor="left"
          open={open}
        >
          <div>
            <IconButton onClick={() => setOpen(false)}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem selected={location.pathname==="/management/dashboard"}>
              <ListItemIcon><DashboardIcon /></ListItemIcon>
              <ListItemText><Link to="/management/dashboard" className="link">Dashboard</Link></ListItemText>
            </ListItem>
            <ListItem selected={location.pathname==="/management/motivations"}>
              <ListItemIcon><InsertCommentIcon /></ListItemIcon>
              <ListItemText><Link to="/management/motivations" className="link">Motivationsprüche</Link></ListItemText>
            </ListItem>
            <ListItem selected={location.pathname==="/management/search"}>
              <ListItemIcon><InsertCommentIcon /></ListItemIcon>
              <ListItemText><Link to="/management/search" className="link">Einzelergebnis</Link></ListItemText>
            </ListItem>
          </List>
        </Drawer>
      </>
    )
  } else {
    return (
      <header>
        <Container maxWidth="lg" className="header-wrapper">
          <div className="logo">
            <a className="link" href="https://walburgaludwig.de" target="_blank"><img className="logo-pic" src={logo} alt="logo"/></a>
          </div>
          {/* <div className="navigation">
            <Link to="/" className="link"><Button color="inherit">Home</Button></Link>
            <Link to="/survey" className="link"><Button color="inherit">Survey</Button></Link>
            <Button color="inherit" onClick={() => toLogin()}>Login</Button>
          </div> */}
        </Container>
      </header>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.management.token !== '' && state.management.token ? true : false
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(Logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);