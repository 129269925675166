import React from 'react'
import { Container } from '@material-ui/core'
import './Footer.scss'

const Footer = () => {
  return (
    <footer>
      <Container maxWidth="lg" className="footer-wrapper">
        <div className="copyright">© 2020 Walburga Ludwig</div>
        <div className="footer-links">
          <ul>
            <li><a href="https://walburgaludwig.de/impressum/" className="link">Impressum</a></li>
            <li><a href="https://walburgaludwig.de/datenschutzerklaerung/" className="link">Datenschutzinformation</a></li>
            <li><a href="https://walburgaludwig.de/kontakt/" className="link">Kontakt</a></li>
          </ul>
        </div>
      </Container>
    </footer>
  )
}
export default Footer;