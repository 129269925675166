import React from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ConfirmAppointment, ConfirmNewsletter } from '../../store/action/management';
import { connect } from 'react-redux';
import {
  useParams
} from "react-router-dom";
import "./emailConfirm.scss"

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff !important",
    backgroundColor: "#626876",
    '&:hover': {
      backgroundColor: "#2d2c2c",
    },
  },
}))(Button);

const EmailConfirm = ({confirm, confirmNewsletter, message}) => {
  let {uuid} = useParams()
  // useEffect(() => {
  //   if (type === "newsletter") {
  //     confirmNewsletter(uuid);
  //   } else if (type === "appointment") {
  //     confirmAppointment(uuid);
  //   }
  // }, [])
  return (
    <Container maxWidth="lg">
      <Typography>Klicken Sie zur Bestätigung auf die Schaltfläche</Typography>
      <div className="newsletter-form">
        {!confirm ? (
          <ColorButton onClick={() => {confirmNewsletter(uuid)}}>Bestätigen</ColorButton>
        ): (
          <Typography>
            {message}
          </Typography>
        )}
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    confirm: state.survey.confirmEmail,
    message: state.survey.confirmMessage
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    confirmNewsletter: (uuid) => dispatch(ConfirmNewsletter(uuid)),
    confirmAppointment: (uuid) => dispatch(ConfirmAppointment(uuid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirm);