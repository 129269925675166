import React from 'react';
import { Container, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { StartSurvey } from '../../store/action/survey';
import { connect } from 'react-redux';

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff !important",
    backgroundColor: "#626876",
    '&:hover': {
      backgroundColor: "#2d2c2c",
    },
  },
}))(Button);

const StartPage = ({start}) => {
  let { url } = useRouteMatch();
  let history = useHistory();
  const toNext = () => {
    history.push(`${url}/questionnaire`)
  }
  return (
    <Container maxWidth="lg" className="start-page">
      <div>
        <h1>Selbstcheck</h1>
        <p>Checken Sie sich selbst! Je nach Lebensphase und Situation sind wir unterschiedlich souverän unterwegs. Manchmal fühlen wir uns stark und zufrieden, manchmal nagen Zweifel an uns und wir wäre gerne klarer, freier und selbstverständlicher unterwegs.</p>
        <p>Möchten Sie erkennen, mit welcher Kräfteverteilung Sie unterwegs sind? Hier kommt ein Angebot zur Selbstreflexion.</p>
        <h2>Bevor Sie in den Selbstcheck einsteigen, …</h2>
        <p>denken Sie an eine Situation, in der Sie sich so richtig souverän erlebt haben …</p>
        <p>denken Sie an eine Situation, in der Sie sich alles andere als souverän erlebt haben …</p>
        <p>denken Sie an eine typische Situation, in der Sie sich zukünftig souveräner bewegen wollen …</p>
        <p>Ihre Auswertung zeigt Ihnen Ihre Tendenzen, mit welchen Neigungen und Fähigkeiten Sie unterwegs sind. Anders als übliche Fragebögen zur Persönlichkeit, variiert das Ergebnis mit Ihrer konkreten Situation. Nutzen Sie die Rückmeldungen als Spiegel, um angeregt durch Fragen und einer ersten Einschätzung sich selbst zu reflektieren. Wenn Sie mit Ihrem Ergebnis arbeiten wollen, nehmen Sie Kontakt mit mir auf. Hierfür biete ich Ihnen ein ½ std. kostenfreies Coaching. Datenschutz ist mir wichtig und ich schütze Ihr persönliches Ergebnis. Diese wird erst mit Ihrer Bestätigung für Sie gespeichert und steht nur dann für ein Beratungsgespräch zur Verfügung. Darüber hinaus können Sie Ihr Ergebnis vor Verlassen der Seite über Ihren Browser ausdrucken. Bei Verlassen der Seite ohne Kontaktaufnahme oder Druck ist Ihr persönliches Ergebnis unwiederbringlich gelöscht. Als Gegenleistung für einen kostenfreien hochwertigen Selbstcheck bitte ich Sie um ein paar wenige Angaben zu Forschungszwecken. Meine Forschungsfrage: Gibt es genderbedingte Unterschiede, wenn ja welche? Wie verändern sie die Qualitäten der Souveränität mit der Berufs- und Lebenserfahrung?</p>
        <p>Mehr erleben Sie in einem individuellen Coaching oder in einem Seminar. Denn diese persönlichen Qualitäten leben vom Erleben. Sie können Ihre Qualitäten und Fähigkeiten im wahrsten Sinne des Wortes sehen, spüren und nachvollziehen. Sie gewinnen Erkenntnisse, die Sie nachhaltig prägen und nutzen werden.</p>
      </div>
      <div>
        <ColorButton onClick={() => start(toNext)}>START</ColorButton>
      </div>
      <div className="more-link">
        <div className="link">
          <a href="https://walburgaludwig.de/kontakt/" className="link">Nehmen Sie Kontakt auf…</a>
        </div>
        <div className="link">
        <a href="https://walburgaludwig.de/kontakt/" className="link">Sie haben Fragen? Gerne helfe ich Ihnen…</a>
        </div>
      </div>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    start: (callback) => dispatch(StartSurvey(callback))
  }
}

export default connect(null, mapDispatchToProps)(StartPage);