import { MANAGEMENT, MOTIVATION } from "../constant"
import Cookies from 'js-cookie';

const initialState = {
  token: Cookies.get("token"),
  pages: [],
  ages: [],
  questions: [],
  genders: [],
  experiences: [],
  newsletters: [],
  motivations: [],
  detail: {}
}

const ManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANAGEMENT.SEARCH + '_FULFILLED':
      state.detail = action.payload.data;
      return {
        ...state
      }
    case MOTIVATION.GET + '_FULFILLED':
      state.motivations = action.payload.data.motivations;
      return {
        ...state
      }
    case MANAGEMENT.LOGOUT:
      state.token = '';
      return {
        ...state
      }
    case MANAGEMENT.LOGIN + '_FULFILLED':
      // console.log(action);
      state.token = action.payload.data.token;
      return {
        ...state
      }
    case MANAGEMENT.GETPAGES + '_FULFILLED':
      if (action.payload.data.pages === null) {
        state.pages = []
      } else {
        state.pages = action.payload.data.pages;
      }
      return {
        ...state
      }
    case MANAGEMENT.GETQUESTIONS + '_FULFILLED':
      if (action.payload.data.questions === null) {
        state.questions = []
      } else {
        state.questions = action.payload.data.questions;
      }
      return {
        ...state
      }
    case MANAGEMENT.GETAGES + '_FULFILLED':
      if (action.payload.data.ages === null) {
        state.ages = []
      } else {
        state.ages = action.payload.data.ages;
      }
      return {
        ...state
      }
    case MANAGEMENT.GETEXPERIENCES + '_FULFILLED':
      if (action.payload.data.experiences === null) {
        state.experiences = []
      } else {
        state.experiences = action.payload.data.experiences;
      }
      return {
        ...state
      }
    case MANAGEMENT.GETGENDERS + '_FULFILLED':
      if (action.payload.data.genders === null) {
        state.genders = []
      } else {
        state.genders = action.payload.data.genders;
      }
      return {
        ...state
      }
    case MANAGEMENT.GETNEWSLETTER + '_FULFILLED':
      if (action.payload.data.newsletters === null) {
        state.newsletters = []
      } else {
        state.newsletters = action.payload.data.newsletters;
      }
      return {
        ...state
      }
    default:
      return state;
  }
}

export default ManagementReducer;